<template>
  <div class="fc integral">
    <div class="card-info tc">
      <h1>{{ integral }}</h1>
      <p>总积分</p>
    </div>
    <div class="f1 scroll list">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getList">
        <van-cell v-for="(item, index) in list" :key="index" :class="item.inPoint > 0 ? 'plus' : 'minus'" size="large" :title="formatTitle(item)" :label="$helper.formatTime(item.weightTime)" :value="(item.inPoint > 0 ? '+' : '-') + item.inPoint" />
      </van-list>
    </div>
    <van-button class="search" round type="info" size="small" @click="showTimePicker = true">搜索</van-button>
    <van-popup v-model="showTimePicker" position="bottom">
      <van-datetime-picker v-model="filterDate" type="date" title="选择日期" :min-date="minDate" :max-date="maxDate"  @cancel="showTimePicker = false" @confirm="getList(), showTimePicker = false"/>
    </van-popup>
  </div>
</template>
<script>
export default {
  name: 'Integral',
  data() {
    return {
      loading: false,
      finished: false,
      page: 1,
      integralArr: [],
      list: [],
      showTimePicker: false,
      filterDate: null,
      minDate: new Date(2021, 0, 1),
      maxDate: new Date(),
    }
  },
  created() {
    this.$http.get(`/point/wxeb17149aeaf910bb/${this.$store.state.token}`).then(res => {
      if (res.code === 0) {
        this.integralArr = res.data
      } else if (res.code === 10019) {
        this.$router.replace('/bind')
      }
    })
  },
  computed: {
    integral() {
      if (this.integralArr instanceof Array && this.integralArr.length > 0) {
        return this.integralArr.reduce((prev, next) => {
          return (prev instanceof Object ? prev.currentPoint : prev) + next.currentPoint
        })
      } else {
        return 0
      }
    }
  },
  methods: {
    getList() {
      this.$http.get(`/throw/page/${this.$store.state.token}`, {
        params: {
          limit: 20,
          page: this.page,
          startDate: this.filterDate ? this.filterDate.valueOf() : '',
          endDate: this.filterDate ? (this.filterDate.valueOf() + (1000 * 3600 * 24)) : ''
        }
      }).then(res => {
        this.loading = false
        if (res.code === 0) {
          this.list = res.data.list
          if (this.page * 20 >= res.data.total) {
            this.finished = true
          } else {
            this.page++
          }
        } else if (res.code === 10019) {
          this.$router.replace('/bind')
        }
      }).catch(() => {
        this.finished = true
        this.loading = false
      })
    },
    formatTitle(item) {
      // 1易腐垃圾，2可回收物，3其他垃圾，4有害垃圾
      switch (item.garbageType) {
        case 1:
          return '易腐垃圾'
        case 2:
          return '可回收物'
        case 3:
          return '其他垃圾'
        case 4:
          return '有害垃圾'
        default:
          return '其他'
      }
    }
  }
}
</script>
<style lang="less" scoped>
.card-info {
  justify-content: flex-start;
  height: 3.6rem;
  width: 100%;
  background-color: #1989fa;
  padding: .7rem 1.3rem;
  font-size: .5rem;
  color: #FFF;
}
.list {
  position: relative;
  background-color: #EFF7FF;
  padding: 0 .3rem .3rem;
  .plus {
    .van-cell__value {
      color: #40AE36;
    }
  }
  .minus {
    .van-cell__value {
      color: #FF7400;
    }
  }
  .van-cell {
    margin-top: .3rem;
    border-radius: .1rem;
  }
}
.search {
  position: absolute;
  bottom: 1.4rem;
  z-index: 10;
  left: 50%;
  margin-left: -30px;
  box-shadow: 0 0 10px #1989FA64;
  width: 60px;
}
</style>